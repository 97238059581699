import { gql } from '@apollo/client';

export const BOLIGLAAN_PROPERTY_QUERY = gql`
  query GetPropertyBoliglaan($input: PropertyInput) {
    getProperty(input: $input) {
      address
      place
      ownerType
      area {
        prom
      }
      price {
        info {
          price
        }
      }
      images {
        first {
          url
        }
      }
    }
  }
`;
