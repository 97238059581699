import * as React from 'react';
import { useLocation, useParams } from 'react-router';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { useMutation, useQuery } from '@apollo/client';

import { SiteContext } from '../../../utils/context/SiteContext';
import { useForm } from '../../../utils/hooks/useForm';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';

import { Section } from '../../layout/Section';
import { Container } from '../../layout/Container';
import { BackgroundLoad } from '../../layout/BackgroundLoad';
import { Thanks } from '../../layout/Thanks';

import { Input } from '../../ui/form/Input';
import { FormError } from '../../ui/form/FormError';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Form, FormRow } from '../../ui/form/Form';
import { Button } from '../../ui/button/Button';
import { ButtonLink } from '../../ui/link/ButtonLink';

import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { Seo } from '../../../utils/seo/Index';

import { Textarea } from '../../ui/form/Textarea';
import { Checkbox } from '../../ui/form/Checkbox';

import {
  LeadBoliglaanFinancing,
  LeadBoliglaanFinancingVariables
} from '../../../__types__/generated/LeadBoliglaanFinancing';

import { currency } from '../../../utils/format/currency';

import { FINANCING_BOLIGLAAN_MUTATION } from '../../../../client/__graphql__/mutations/financingBoliglaan';
import { LeadType, PageType } from '../../../__types__/generated/globalTypes';

import {
  GetPropertyBoliglaan,
  GetPropertyBoliglaanVariables
} from '../../../__types__/generated/GetPropertyBoliglaan';
import { BOLIGLAAN_PROPERTY_QUERY } from '../../../../client/__graphql__/queries/getPropertyBoliglaan';
import { DiggerType, useDiggerId } from '../../../utils/hooks/useDiggerId';

const Boliglan: React.FC = () => {
  const location = useLocation();
  const params = useParams<{ sguid: string }>();
  const [consent, setConsent] = React.useState(false);

  const { submitWithDigger } = useDiggerId();

  const { data } = useQuery<
    GetPropertyBoliglaan,
    GetPropertyBoliglaanVariables
  >(BOLIGLAAN_PROPERTY_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        sguid: params.sguid,
        sold: false
      }
    }
  });

  const { routeConfig }: any = React.useContext(SiteContext);

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const [posted, setPosted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const [lead, { error }] = useMutation<
    LeadBoliglaanFinancing,
    LeadBoliglaanFinancingVariables
  >(FINANCING_BOLIGLAAN_MUTATION, {
    onCompleted: ({ lead }) => {
      setLoading(false);
      if (lead?.financing?.success) {
        window.scroll(0, 0);
        setPosted(true);
      }
    }
  });

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'firstName',
        placeholder: 'Eks. Ola',
        value: '',
        label: 'Fornavn *',
        required: true
      },
      {
        type: 'text',
        name: 'lastName',
        placeholder: 'Eks. Nordmann',
        value: '',
        label: 'Etternavn *',
        required: true
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eks. 1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ola@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'textarea',
        name: 'comment',
        placeholder: 'Kommentar',
        value: '',
        label: 'Kommentar'
      },
      {
        type: 'checkbox',
        name: 'consentContact',
        checked: false,
        label: 'Jeg anmoder om å bli kontaktet av Nordea angående finansiering.'
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);

      setLoading(true);
      submitWithDigger(
        DiggerType.FINANSIERING,
        {
          zip: preparedFields.zip
        },
        () => {
          lead({
            variables: {
              input: {
                identifier: params.sguid,
                leadType: LeadType.FINANSIERING,
                pageType: PageType.OBJEKT,
                firstName: preparedFields.firstName,
                lastName: preparedFields.lastName,
                address: data?.getProperty?.address,
                zip: preparedFields.zip,
                phone: preparedFields.phone,
                email: preparedFields.email,
                comment: preparedFields.comment,
                referer: window.location.href,
                consents: {
                  finansiering: preparedFields.consentContact
                }
              }
            }
          });
        }
      );
    }
  });

  React.useEffect(() => {
    if (fields) {
      if (fields.filter((item) => item?.type === 'checkbox')[0]?.checked) {
        setConsent(true);
      } else {
        setConsent(false);
      }
    }
  }, [fields]);

  return (
    <>
      <Seo
        title="Boliglån - PrivatMegleren"
        description="Få tilbud på boliglån fra Nordea"
        url={location.pathname}
      />
      <Container className="padding-top padding-bottom" style={pageFadeIn}>
        <FormWrapper style={fadeOut}>
          <Section className="form-section" style={{ paddingBottom: '10px' }}>
            <Heading tag="h1" center={true}>
              Finansiering
            </Heading>
          </Section>
          <PropertyData
            sguid={params?.sguid}
            address={data?.getProperty?.address}
            place={data?.getProperty?.place}
            ownerType={data?.getProperty?.ownerType}
            prom={data?.getProperty?.area?.prom}
            price={data?.getProperty?.price?.info?.price}
            image={data?.getProperty?.images?.first?.url}
          />
          <Form onSubmit={handleSubmit} noValidate>
            {error &&
              error.graphQLErrors.map((error, index) => (
                <FormError key={`error_${index}`} message={error.message} />
              ))}
            {fields
              .filter(
                (item) => item?.type !== 'textarea' && item?.type !== 'checkbox'
              )
              .map((item, index) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Input
                      type={item?.type}
                      name={item?.name}
                      label={item?.label}
                      labelAfter={item?.labelAfter}
                      placeholder={item?.placeholder}
                      value={item?.value}
                      onChange={handleChange}
                      error={item?.error}
                    />
                  </FormRow>
                );
              })}
            {fields
              .filter((item) => item?.type === 'textarea')
              .map((item, index) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Textarea
                      name={item?.name}
                      label={item?.label}
                      labelAfter={item?.labelAfter}
                      placeholder={item?.placeholder}
                      value={item?.value}
                      onChange={handleChange}
                      error={item?.error}
                    />
                  </FormRow>
                );
              })}
            {fields
              .filter((item) => item?.type === 'checkbox')
              .map((item, index) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Checkbox
                      labelPos="right"
                      name={item?.name}
                      label={item?.label}
                      checked={item?.checked}
                      onChange={handleChange}
                      error={item?.error}
                    />
                  </FormRow>
                );
              })}
            <ButtonGroup>
              <div
                className="button-wrapper"
                style={{
                  ...(consent
                    ? { opacity: 1, pointerEvents: 'auto' }
                    : { opacity: 0.5, pointerEvents: 'none' })
                }}
              >
                <Button
                  type="primary"
                  colorTheme="gold"
                  disabled={loading}
                  loading={loading}
                >
                  Send til Nordea
                </Button>
              </div>
              <ButtonLink
                as="a"
                href={routeConfig.personvern.url}
                className="privacy-link-mobile"
              >
                Personvernpolicy
              </ButtonLink>
            </ButtonGroup>
            <Paragraph
              center={true}
              style={{ fontSize: '14px', marginTop: '40px' }}
            >
              PrivatMegleren er Nordeas eiendomsmeglerkjede. Våre meglerforetak
              mottar vederlag for formidling av kunder.{' '}
            </Paragraph>
          </Form>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h1" center={true}>
              Takk for din henvendelse
            </Heading>
            <Paragraph center={true}>
              Du vil motta en sms fra Nordea om kort tid.
            </Paragraph>
          </Section>
        </Thanks>
      </Container>
      <BackgroundLoad
        opacity={0.3}
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/falk_bakside2.jpg"
      />
    </>
  );
};

export default Boliglan;

const FormWrapper = styled(animated.div)``;

interface IPropertyData {
  sguid?: string | null | undefined;
  address?: string | null | undefined;
  place?: string | null | undefined;
  ownerType?: string | null | undefined;
  price?: number | null | undefined;
  prom?: string | null | undefined;
  image?: string | null | undefined;
}

const PropertyData: React.FC<IPropertyData> = ({
  sguid,
  address,
  place,
  ownerType,
  price,
  prom,
  image
}) => {
  return (
    <StyledPropertyData href={`https://privatmegleren.no/${sguid}`}>
      <StyledPropertyDataInside>
        <img src={image} alt="bilde" />
        <StyledPropertyInfo>
          <h3 style={{ marginBottom: 0 }}>{address}</h3>
          <h3 style={{ marginTop: '10px' }}>{place}</h3>
          <p>
            {ownerType} - {prom}m<sup>2</sup>
          </p>
          <h3>
            {currency({
              number: price,
              seperator: '.'
            })}
            ,-
          </h3>
        </StyledPropertyInfo>
      </StyledPropertyDataInside>
    </StyledPropertyData>
  );
};

const StyledPropertyData = styled.a`
  display: block;
  width: 100%;
  float: left;
  margin-bottom: 30px;
`;

const StyledPropertyDataInside = styled.div`
  display: block;
  width: 100%;
  max-width: 550px;
  margin: 0 auto 0 auto;

  img {
    width: 50%;
    height: 205px;
    object-fit: cover;
    float: left;
  }

  @media all and (max-width: 680px) {
    img {
      width: 100%;
    }
  }
`;

const StyledPropertyInfo = styled.div`
  width: 50%;
  padding: 20px;
  background: black;
  float: left;

  @media all and (max-width: 680px) {
    width: 100%;
  }
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 10px;
  width: 100%;

  @media all and (max-width: 350px) {
    grid-template-columns: max-content;
    grid-row-gap: 20px;
    justify-content: center;

    .button-wrapper {
      justify-self: center;
    }

    a {
      text-align: center;
    }
  }
`;
