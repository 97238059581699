import { gql } from '@apollo/client';

export const FINANCING_BOLIGLAAN_MUTATION = gql`
  mutation LeadBoliglaanFinancing($input: LeadInput!) {
    lead(input: $input) {
      financing {
        success
      }
    }
  }
`;
